<template>
  <a-spin :spinning="fileRequestLoading" class="right-file-loading h-full" :tip="tips">
    <div style="width: 100%; height: 6px; background: #fff"></div>
    <div class="file-list">
      <div class="file-list-header">
        <div :class="[showRoute ? 'file-list-header-left' : 'file-list-header-show-input']" @click="inputPath">
          <div v-if="showRoute" style="display: flex; align-items: center;width: 100%">
            <div class="file-list-header-left-back" v-if="fileRoute.length" @click="backFile($event)">
              <svg-icon icon-class="file-back" />
            </div>
            <div class="header-right">
              <div class="ell" :style="{'display': 'flex', 'align-items': 'center'}">
                <div v-for="(item, index) in fileRoute" :key="index" :class="['route-item', fileRoute.length -1 === index ? 'route-item-selected': '']" @click="getFile(item, index, $event)">
                  <a-dropdown v-if="index > 0" :trigger="['click']" overlayClassName="more-dir">
                    <a-menu v-if="item.fileList && item.fileList.length && index > 0" slot="overlay" @click="($event) => handleMenuClick($event, item)">
                      <a-menu-item v-for="child in item.fileList" :key="child.fullpath">{{ child.name }}</a-menu-item>
                    </a-menu>
                    <div class="route-item-name" @click="($event) => clickDropDown($event, index, item)">
                      <a-icon style="font-size: 10px;" :type="item.icon ? item.icon : 'right'" />
                    </div>
                  </a-dropdown>  
                  <span class="route-item-name"> {{ item.name }}</span> 
                </div>
              </div>
            </div>
            <div class="reload-icon" @click="refreshFile($event)">
              <a-icon type="reload" />
            </div>
          </div>
          <div v-else style="width: 100%;height: 30px;" >
            <a-input v-model="routePath" ref="routeInput" style="width: 100%;height: 30px;" @pressEnter="changePath" @blur="showRoute = true"/>
          </div>
        </div>
        <div class="file-list-header-right">
          <a-input style="width: 180px; height: 30px;" class="height30" v-model="searchValue" @pressEnter="changeSearchValue" placeholder="请输入关键字查询">
            <svg-icon slot="suffix" icon-class="realtime-search" />
          </a-input>
          <!-- <a-button v-if="!readFileObj.is_dir" type="text" :disabled="!readFileObj.is_dir" class="mgl8 height30" @click="upload">
            <svg-icon style="margin-right: 4px" icon-class="file-upload" />上传
          </a-button> -->
          <a-button v-if="!fileRoute.length && readFileObj.is_dir" type="text" :disabled="!readFileObj.is_dir" class="mgl8 height30" @click="upload">
            <svg-icon style="margin-right: 4px" icon-class="file-upload" />上传
          </a-button>
          <!-- treeFileId 是点击左侧的树，树上的文件系统id-->
          <uploadFile ref="uploadFileRef" v-if="fileRoute.length && readFileObj.is_dir" class="mgl8 height30" :fileRoute="fileRoute" :file="selectFile" :treeFileId="fileSystemId" :clusterId="clusterId" :treeFilePath="propPath" @refreshRoute="refreshRoute"/>
          <a-button v-if="readFileObj.is_dir" type="text" class="mgl8 height30" :disabled="!readFileObj.is_dir" @click="mkdir">
            <svg-icon style="margin-right: 4px" icon-class="file-add" />新建
          </a-button>
          <a-button v-if="!readFileObj.is_dir" type="text" class="mgl8 height30" @click="quickOpt">
            <svg-icon style="margin-right: 4px;font-size: 17px;" icon-class="file-quick" />快捷键
          </a-button>
          <a-button type="text" class="mgl8 height30" @click="downFile()">
            <svg-icon style="margin-right: 4px" icon-class="file-down" />下载
          </a-button>
          <a-button type="text" class="mgl8 height30" v-if="readFileObj.is_dir" @click="deleteFile()">
            <svg-icon style="margin-right: 4px" icon-class="filemanage-del" />删除
          </a-button>
          <a-button type="text" class="mgl8 height30" v-if="!readFileObj.is_dir" @click="saveFile">
            <svg-icon style="margin-right: 4px" icon-class="file-save" />保存
          </a-button>
          <div class="file-show-type mgl8">
            <span :class="['show-warp', 'show-warp-card', 'show-warp-seleted']" @click="changeTab()">
              <svg-icon :icon-class="showTab !== 'card' ? 'file-card' : 'file-list'" />
            </span>
          </div>
        </div>
      </div>
      <div class="file-list-body" ref="draggable" v-if="readFileObj.is_dir" :style="{'padding-right': showTab==='card' ? '0px': '0px'}">
        <file-card ref="fileCardRef" v-if="showTab==='card'" :treeVisible="treeVisible" :fileRequestLoading="fileRequestLoading" 
        :fileList="fileList" :fileSystemId="fileSystemId" :fileRoute="fileRoute" :file="selectFile" :treeFileId="fileSystemId" :clusterId="clusterId" 
        :treeFilePath="propPath" @refreshRoute="refreshRoute" @onGetProxyFile="onGetProxyFile" @readFile="readFile" @getEdit="getEdit" @deleteFile="deleteFile" 
        @changeFileRequestLoading="changeFileRequestLoading" @mkdir="mkdir" @handleUploadClick="handleUploadClick" @refreshFile="refreshFile" @downFile="downFile" 
        @openFile="openFile" @openSsh="openSsh" @editFileByOnlyOffice="editFileByOnlyOffice" />
        <file-list ref="fileListRef" v-else :fileList="fileList" :fileSystemId="fileSystemId"  :fileRequestLoading="fileRequestLoading" :hasSearch="hasSearch" @readFile="readFile" @onGetProxyFile="onGetProxyFile" @getEdit="getEdit" @deleteFile="deleteFile" @changeFileRequestLoading="changeFileRequestLoading" @mkdir="mkdir" @handleUploadClick="handleUploadClick" @downFile="downFile" @refreshFile="refreshFile" @openFile="openFile" @openSsh="openSsh" />
      </div>
      <EditFile
          ref="EditFileRef"
          :clusterId="clusterId"
          v-else
          :fileCode="readFileObj.code"
          :language="readFileObj.lang"
          :file="readFileObj.file"
          :fileSystemId="fileSystemId"
        />
      <drawerSshVue ref="drawerSshVueRef" :isClosable="isClosable" :activeKey="activeKey" :clusterId="clusterId" :isFullScreen="isFullScreen" :currentFullPathArr="fileRoute" :drawerVisible="drawerVisible" :fileSystemId="fileSystemId" @onClose="closeSsh" @changeClosable="changeClosable" @changeFullScreen="changeFullScreen"  />
    </div>
    
  </a-spin>
</template>

<script>
import fileCard from "./fileCard";
import fileList from "./fileList";
import MkdirFile from "./mkdir"
import uploadFile  from "./uploadFile";
import DeleteFile  from "./deleteFile";
import { mapActions, mapState } from "vuex";
import EditFile from './editFile'
import { downloadFileBlob } from '@/utils/util'
import { debounce } from 'lodash';
import { isOfficeFile, generateProgressHtml} from './office'
import drawerSshVue from '@/components/SshTerminal/drawerSsh.vue';
import { downloadFilesConcurrently, cancelDownload } from '@/utils/downloadChannel'
import { getConnection, removeConnection } from '@/utils/axios-peer'
import { guid } from '@/utils//axios-peer'
import store from '@/store'
import axios from 'axios';

export default {
  props: {
    fileSystemId: [String, Number],
    activeKey: String,
    treeVisible: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      refreshRoute: this.refreshRoute
    }
  },
  inject: ['windowEventBus', 'windowAppLoaderOptions'],
  data() {
    return {
      drawerVisible: false, 
      isFullScreen: false,
      isClosable: true,
      tips: '',
      fileRequestLoading: false,
      searchValue: "",
      showTab: "card",
      fileList: [],
      fileRoute: [{
        name:'根目录',
        fullpath: '/',
        fileList: [],
        icon: 'right'
      }],
      readFileObj:{
        editLoading: false,
        is_dir: true,
        code: '',
        file: {},
        lang: 'powershell'
      },
      selectFile: {},
      showRoute: true,
      routePath: '',
      hasSearch: false,
    };
  },
  watch: {
    fileRoute: {
      handler(val) {
        console.log(val, "fileRoutefileRoutefileRoutefileRoute");
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    propPath () {
      const str = this.getRequestFullPath()
      return str
    },
    dropdownFileList () {
      console.log(this.fileList.filter(item => item.is_dir), 'this.fileList.filter(item => item.is_dir)')
      return this.fileList.filter(item => item.is_dir)
    },
    ...mapState({
      groupManage: (state) => state.fileManage,
      winboxHostModule: (state) => state.winboxHostModule,
    }),
    // 当前winbox的ID
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    winboxHost () {
      return this.winboxHostModule[this.currentWinboxKey]
    },
    clusterId () {
      return this.winboxHost?.showItem.master
    },
    inputWidth () {
      return '100%'
    }
  },
  components: {
    fileCard,
    fileList,
    uploadFile,
    EditFile,
    drawerSshVue,
    ExternalComponent: () => import('@/components/browser/index.vue'),
    FilePreview: () => import('@/components/filePreview')
  },
  mounted() {
    const self = this
    if (this.winboxHost.showItem.fromTo === 'ssh' && this.winboxHost.showItem.fromToFileFirst) {
      this.getProxyFile({...this.winboxHost.showItem}, 'route')
      this.changeSshFromTo()
    }
    this.windowEventBus.$on("onOpenFile", (file, parentFile) => {
      if (this.fileSystemId !== this.winboxHost.showItem.id) return false
      this.handleSearchDebounced(file, parentFile)
    });
    this.windowEventBus.$on("onGetProxyFile", (node, type) => {
      if (self.fileSystemId !== self.winboxHost.showItem.id) return false
      self.selectFile = node
      self.getProxyFile(node, type);
    });
    this.windowEventBus.$on("onGetEditFile", (data, file) => {
      if (self.fileSystemId !== self.winboxHost.showItem.id) return false
      let code = data
      this.lang = 'powershell'
      if (Object.prototype.toString.call(code) === '[object Object]') {
        code = JSON.stringify(code, null, " ");
      }
      this.readFileObj.code = code + ''
      this.readFileObj.file = file
      this.readFileObj.is_dir = false
      if (this.$refs.EditFileRef) this.$refs.EditFileRef.resetCode(code)
      this.getFileRoute(file.fullpath)
    });
    this.windowEventBus.$on("setFileRequestLoading", (flag) => {
      if (self.fileSystemId !== this.winboxHost.showItem.id) return false
      this.changeFileRequestLoading(flag)
    });
    this.windowEventBus.$on("changeLoading", (loading) => {
      if (self.fileSystemId !== this.winboxHost.showItem.id) return false
      if (loading === 'loading') {
        self.searchValue = ''
        self.tips = ''
        if (self.showTab === 'card') self.hasSearch = false
      }
      self.loading = loading === 'loading'
      if (this.readFileObj.is_dir) self.fileRequestLoading = loading === 'loading'
    });
    this.windowEventBus.$on("onGetFirstTree", (data, node) => {
      if (self.fileSystemId !== this.winboxHost.showItem.id) return false
      self.selectFile = node
      let arr = node.parentArr ? _.cloneDeep(node.parentArr) : []
      // 初始化 
      self.fileRoute = arr
      self.fileList = []
      this.readFileObj.is_dir = true
      if (data && data.length > 0) {
        data.map((val) => {
          self.fileList.push({
            title: val.name,
            name: val.name,
            svgIcon: "file-dir",
            key: val.fullpath,
            isLeaf: !val.is_dir,
            id: val.fullpath,
            pid: node.isChild
              ? node.pid
              : node.id,
            isChild: true,
            parentArr: arr,
            scopedSlots: { title: 'host' },
            ...val,
          });
        });
      }
    });
  },
  beforeDestroy() {
    // this.windowEventBus.$off("onGetProxyFile");
    // this.windowEventBus.$off("changeLoading");
    // this.windowEventBus.$off("onGetFirstTree");
    // this.windowEventBus.$off("onGetEditFile");
    // this.windowEventBus.$off("setFileRequestLoading");
  },
  methods: {
    ...mapActions("fileManage", [
      "setFileRequestLoading",
      "changeShowItem",
      "changeRealEditList",
      "changeShowFileProgress",
      "changeDownloadFileList ",
      "changeUpload"
    ]),
    ...mapActions("winboxHostModule", [
      "changeWinboxRealEditList",
      "changeWinboxShowItem",
      "changeWinboxShowFileProgress",
    ]),
    changeSshFromTo() {
      const showItem = this.winboxHost.showItem
      this.changeShowItem({
        ...showItem,
        fromToFileFirst: false
      })
      this.changeWinboxShowItem({id: this.currentWinboxKey, value: {
        ...showItem,
        fromToFileFirst: false
      }});
      const list = this.winboxHost.realEditList
      const index = list.findIndex(item => item.id === this.winboxHost.showItem.id)
      if (index > 0) {
        list.splice(index, 1, {
          ...showItem,
          fromToFileFirst: false
        });
        this.changeRealEditList(list)
        this.changeWinboxRealEditList({id: this.currentWinboxKey, value:list});
      }
    },
    handleSearchDebounced: debounce(function(file, parentFile) {
      // 处理搜索逻辑
      this.changeFileRequestLoading(false)
      this.getProxyFile({...file, parentFile}, 'openFile');
    }, 1000),
    // 调用编辑器的实例里面的快捷键方法
    quickOpt () {
      const editor = this.$refs.EditFileRef.$refs.editorFile.monacoEditor;
      if (editor) {
        editor.focus();
        editor.trigger('keyboard', 'editor.action.quickCommand');
      }
    },
    // 左边树点击和右边卡片或者list点击文件夹或者文件触发事件
    onGetProxyFile(node, type) {
      this.selectFile = node
      this.getProxyFile(node, type);
    },
    // 
    changeFileRequestLoading (flag) {
      this.fileRequestLoading = flag
    },
    editFileByOnlyOffice (file) {
      const self = this
      const toHtml = generateProgressHtml
      let apiAjax = 'side/filesystem/edit/files/root' + file.fullpath + '?filesystem=' + this.winboxHost.showItem.id + '&cluster_id=' + this.clusterId + `&action=edit`
      let appLoaderOptions = {
            url: "https://static-www.onlyoffice.com/v9.5.0/images/favicons01/favicon32.png",
            name: file.fullpath,
            fileCompontentType: "ExternalComponent",
            ExternalComponent: {
              externalUrl: "",
            }, 
            componentName: 'ExternalComponent',
            id: file.fullpath,
      }
      self.$openWindow(file.id, self.$options.components, {}, {}, {...appLoaderOptions});
      const updateServerProgress = function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        const blob = new Blob([toHtml(file.name,percentCompleted)], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        appLoaderOptions.ExternalComponent.externalUrl = url;
      }
      const response = axios.put(apiAjax, {}, {
        headers: {
          token: localStorage.getItem('token'),
          Accept: 'text/event-stream'
        },
        timeout: 0, // 取消超时限制
        onUploadProgress: updateServerProgress,
        onStreamEvent: updateServerProgress
      }).then(function (res) {
        // 生成 Blob URL
        const blob = new Blob([res.data?.data], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        appLoaderOptions.ExternalComponent.externalUrl = url; 
      })
    },
    // 预览文件
    openFile (file) {
      const self = this;
      const toHtml = generateProgressHtml
      if (isOfficeFile(file.fullpath)) {
        let apiAjax = 'side/filesystem/edit/files/root' + file.fullpath + '?filesystem=' + this.winboxHost.showItem.id + '&cluster_id=' + this.clusterId;
        let appLoaderOptions = {
              url: "https://static-www.onlyoffice.com/v9.5.0/images/favicons01/favicon32.png",
              name: file.fullpath,
              fileCompontentType: "ExternalComponent",
              ExternalComponent: {
                externalUrl: "",
              }, 
              componentName: 'ExternalComponent',
              id: file.fullpath,
        }
        self.$openWindow(file.id, self.$options.components, {}, {}, {...appLoaderOptions});
        const updateServerProgress = function(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const blob = new Blob([toHtml(file.name,percentCompleted)], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          appLoaderOptions.ExternalComponent.externalUrl = url;
        }
        const response = axios.put(apiAjax, {}, {
          headers: {
            token: localStorage.getItem('token'),
            Accept: 'text/event-stream'
          },
          timeout: 0, // 取消超时限制
          onUploadProgress: updateServerProgress,
          onStreamEvent: updateServerProgress
        }).then(function (res) {
          const blob = new Blob([res.data?.data], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          appLoaderOptions.ExternalComponent.externalUrl = url; 
        })
      } else {
        const id = this.winboxHost.showItem.id
        if (this.fileRequestLoading) return false
        // 文件夹不行
        if (file.is_dir) return false;
        const arr = file.name.split(".");
        // 没有后缀名不给编辑
        // if (!arr.length) return false
        let key = "";
        if (arr.length > 0) key = arr[arr.length - 1];
        // this.readFile(1, '文件获取中')
        const width = ['mp3'].includes(key) ? 456 :  document.body.clientWidth
        let IconUrl = global.utils.getIcon(file.name)
        console.log(IconUrl, 'IconUrlIconUrlIconUrl', file.id)
        let appLoaderOptions = {
            url: IconUrl,
            name: file.name,
            fileCompontentType: "FilePreviewComponent",
            componentName: 'FilePreview',
            id: file.id,
          }
          const propData = {
          file: file,
          clusterId: self.clusterId,
          filesystemId: id,
          callBack: 
            () => {
              self.readFile(0, '')
            }
        }
        this.$openWindow(file.id, this.$options.components, {propData}, {}, {...appLoaderOptions});
      }
    },
    // 编辑器保存文件
    saveFile () {
      this.$refs.EditFileRef.submit()
    },
    readFile (loading, tips) {
      this.fileRequestLoading = loading === 1
      this.tips = tips
    },
    mergeArrays(arr1, arr2) {
      let str = ''
      // arr1: 是输入框里面的值
      // arr2: 是当前fileroute路由值
      var mergedArray = [];
      for (var i = 0; i < arr1.length; i++) {
        var matchFound = true;
        // 检查之前索引处的元素是否相同
        for (var j = 0; j < i && j < arr2.length; j++) {
          if (arr1[j] !== arr2[j].title) {
            matchFound = false;
            break;
          }
        }
        // 检查当前索引处的元素是否相同
        if (matchFound && arr2[i] && arr1[i] === arr2[i].title) {
          mergedArray.push(arr2[i]);
        } else {
          mergedArray.push({
            name: arr1[i],
            fullpath: str += '/' + arr1[i],
            fileList: [],
            icon: 'right'
          });
        }
      }
      return mergedArray;
    },
    // 处理输入框里面输入文件内容切换 特殊处理下拉菜单
    handleInputChangeRoute (routePath) { 
      const fileRouteArr =this.fileRoute
      const routePathArr = routePath.split('/') 
      fileRouteArr.shift()
      routePathArr.shift()
      let arr = [{
        name: '根目录',
        fullpath: '/',
        fileList: [],
        icon: 'right'
      }]
      const arr3 = arr.concat(this.mergeArrays(routePathArr, fileRouteArr))
      if (fileRouteArr.length < routePathArr.length) {
        arr3[fileRouteArr.length + 1].fileList = this.fileList
      }
      this.fileRoute = arr3
    }, 
    getFileRoute(routePath, dropdown) {
      let arr = [{
        name: '根目录',
        fullpath: '/',
        fileList: [],
        icon: 'right'
      }]
      let str = ''
      let pathArr = routePath.split('/').filter(item => item)
      console.log(this.fileRoute, pathArr, '222')
      if (pathArr.length > 0) {
        pathArr.map((item, index) => {
          arr.push({
            name: item,
            fullpath: str += '/' + item,
            fileList: dropdown === 'dropdown' ? this.fileRoute[index + 1]?.fileList || [] : this.fileList.filter(child => child.is_dir)
          })
        })
      }
      this.fileRoute = arr
    },
    inputPath () {
      let str = this.fileRoute[this.fileRoute.length -1].fullpath
      this.routePath = str.includes('//') ? str.replaceAll('//', '/') : str
      this.showRoute = false
      this.$nextTick(() => {
        this.$refs.routeInput.focus()
      })
    },
    clickDropDown (e, index, item) {
      if (e) e.stopPropagation()
      if (index > 0 && (!item.fileList || !item?.fileList.length)) {
        let lastIndex = item.fullpath.lastIndexOf("/");
        let result = item.fullpath.substring(0, lastIndex);
        if (index === 1) result = '/'
        let apiAjax = global.API.getProxyFile + `${result}`;
        const params = {
          filesystem: this.winboxHost.showItem.id, // 取当前这个Tab页签的filesystemId
          keyword: this.searchValue,
          cluster_id: this.clusterId
        };
        let arr = []
        this.$set(this.fileRoute[index], 'icon', 'loading');
        this.$axiosProxyGet(apiAjax, params).then((res) => {
          res.data.map((val) => {
            if (val.is_dir) {
              arr.push({
                title: val.name,
                name: val.name,
                svgIcon: "file-dir",
                key: val.fullpath,
                isLeaf: !val.is_dir,
                id: val.fullpath,
                isChild: true,
                parentArr: [],
                scopedSlots: { title: 'host' },
                ...val,
              });
            }
          });
          this.$set(this.fileRoute[index], 'icon', 'right');
          this.$set(this.fileRoute[index], 'fileList', arr);
        })
      }
    },
    handleMenuClick (e, item) {
      if (e?.domEvent) e?.domEvent.stopPropagation()
      this.showRoute = true
      this.getProxyFile({...this.selectFile, fullpath: e.key, parentFileList: item.fileList}, 'dropdown')
    },
    changePath () {
      if (!this.routePath) return this.$message.warning('请输入路径')
      this.showRoute = true
      this.getProxyFile({...this.selectFile, fullpath: this.routePath}, 'input')
    },
    // 二进制文件打开 获取二进制内容 打开编辑器
    getEdit(file, refresh){
      if (this.fileRequestLoading) return false
      // 文件夹不行
      if (file.is_dir) return false;
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      // if (!arr.length) return false
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // if(!['txt', 'go', 'js', 'vue', 'yaml', 'sh', 'java', 'log', 'md', 'sql', 'html'].includes(key)) return false
      // if ((file.size / 1048576) > 2) {
      //   this.fileRequestLoading = false
      //   return this.$message.warning('文件大小超过2M，请使用其它编辑方式')
      // } 
      this.readFile(1, '文件读取中')
      const self = this;
      const ajaxApi = global.API.downloadProxyFile
      this.$axiosProxyDown(ajaxApi + file.fullpath, { filesystem: this.winboxHost.showItem.id, cluster_id: this.clusterId}).then((res) => {
        this.readFile(0, '')
        if ([200, 201, 204].includes(res.status)) { 
          return self.$decodeData(res.data)
        } else {
          throw new Error('Unexpected HTTP status code');
        }
      }).then(code => {
            this.lang = 'powershell'
            if (Object.prototype.toString.call(code) === '[object Object]') {
              code = JSON.stringify(code, null, " ");
            }
            self.readFileObj.code = code + ''
            self.readFileObj.file = file
            self.readFileObj.is_dir = false
            // 如果是刷新的话需要重置编辑起里面的内容
            if (refresh === 'refresh') self.$refs.EditFileRef.resetCode(code)
            self.getFileRoute(file.fullpath)
      }).catch(res => {
        this.readFile(0, '')
        this.$message.error('当前文件获取内容失败')
      })
    },
    // 操作之后 刷新当前文件
    refreshFile (e) {
      if (e) e.stopPropagation();
      if (!this.readFileObj.is_dir) {
        return this.getEdit(this.readFileObj.file, 'refresh')
      }
      // this.showTab === 'card' && this.$refs.fileCardRef?.scrollToTop() 
      this.getProxyFile(this.selectFile, 'route')
    },
    // 刷新当前路由
    refreshRoute (type, fileRoute, treeFileId) {
      const obj = {
        type, fileRoute, treeFileId
      }
      console.log(obj)
      this.getProxyFile({...this.selectFile,fullpath: this.routePath}, 'route' , 'file', obj)
    },
    handleOutsideClick(event) {
      // 判断点击事件是否发生在抽屉外部
      if (this.$refs.drawerSshVueRef && !this.$refs.drawerSshVueRef.$el.contains(event.target) && this.isClosable) {
        this.closeSsh();
      }
    },
    // 打开终端
    openSsh (e) {
      if (e) e.stopPropagation()
      this.drawerVisible = true
      this.$refs.drawerSshVueRef.exitFlag = true
      document.getElementById("popContainer").addEventListener("click", this.handleOutsideClick);
    },
    
    changeClosable () {
      this.isClosable = !this.isClosable
    },
    closeSsh () {
      this.isFullScreen = false
      this.drawerVisible = false
      document.getElementById("popContainer").removeEventListener("click", this.handleOutsideClick);
    },
    changeFullScreen (flag) {
      this.isFullScreen = flag
    },
    // 新建 
    mkdir () {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      const path = this.getRequestFullPath()
      const self = this;
      let width = 600;
      let content = (
        <MkdirFile
          file={self.selectFile}
          treeFileId={self.winboxHost.showItem.id}
          treeFilePath={path}
          clusterId={self.clusterId}
          callBack={() => self.getProxyFile({...self.selectFile}, 'route')}
        />
      );
      this.$confirm({
        width: width,
        title: '新建',
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 下载文件
    downFile (file) {
      let arr = []
      let selectFileList = [] 
      if (!file) {
        if (this.readFileObj.is_dir) {
          if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
          arr = this.showTab === 'card' ? this.$refs.fileCardRef.checkFileList : this.$refs.fileListRef.selectedRows
          selectFileList = arr.filter(item => !item.is_dir)
          if (!selectFileList.length) return this.$message.warning('请至少选择一个文件')
        } else {
          selectFileList = [this.readFileObj.file]
        }
      } else {
        selectFileList = [file]
      }
      this.changeShowFileProgress(true)
      this.changeUpload('download')
      const stateDownloadList = this.groupManage.downloadFileList
      let downloadList = []
      selectFileList.forEach(item => {
        const fileId = guid()
        item.fileId = fileId
        item.clusterId = this.clusterId
      })
      selectFileList.map((file) => {
        downloadList.push({
          ...file,
          progress: 0
        })
      })
      store.dispatch('fileManage/changeDownloadFileList', stateDownloadList.concat(downloadList))
      if(!store.state.fileManage.showFileProgress) store.dispatch('fileManage/changeDownloadFileList', [])
      const url = 'side/filesystem/files/root'
      downloadFilesConcurrently(selectFileList, url, {clusterId: this.clusterId,  filesystem: this.winboxHost.showItem.id}, 3).then(results => {
        console.log("所有文件下载结果:");
        cancelDownload()
        selectFileList.map(item => {
          const conDown = getConnection(this.clusterId, item.name)
          if (conDown) conDown.close()  
          removeConnection(this.clusterId, item.name)
        })
      })
      .catch(error => {
        console.error("下载过程中遇到错误:", error);
      });
      // selectFileList.map(item => { 
      //   // localStorage.getItem('FILE_SYSTEM_URL') +
      //   let apiAjax = 'side/filesystem/files/root'
      //   downloadFileBlob(apiAjax + item.fullpath + '?cluster_id=' + this.clusterId + '&filesystem=' + this.winboxHost.showItem.id, item, {filesystem: this.winboxHost.showItem.id})
      //   return;
      // })
    },
    upload () {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
    },
    // 外部按钮调用上传
    handleUploadClick() {
      // 获取 Upload 组件的引用
      const upload = this.$refs.uploadFileRef.$refs.fileInput;
      // 调用 click 方法触发文件选择对话框
      upload.click();
    },
    changeSearchValue() {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      this.showTab = 'list'
      this.hasSearch = true
      this.getProxyFile({...this.selectFile, fullpath: this.routePath}, 'route', 'search')
    },
    // 删除选中的文件
    deleteFile (file) {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      let arr = []
      if (!file) {
        arr = this.showTab === 'card' ? this.$refs.fileCardRef.checkFileList : this.$refs.fileListRef.selectedRows
      } else {
        arr = [file]
      }
      if (!arr.length) return this.$message.warning('请至少选择一个文件')
      const self = this;
      let width = 300;
      let content = (
        <DeleteFile
          file={this.selectFile}
          fileList={arr}
          treeFileId={this.winboxHost.showItem.id}
          clusterId={this.clusterId}
          callBack={() => {
            self.getProxyFile(self.selectFile, 'route');
            if (this.showTab === 'card') {
              this.$refs.fileCardRef.checkFileList = []
            } else {
              this.$refs.fileListRef.selectedRows = []
              this.$refs.fileListRef.selectedRowKeys = []
            }
          }}
        />
      );
      this.$confirm({
        width: width,
        title: '删除',
        content,
        closable: true,
        mask:false,
        icon: () => {
          return <div />;
        },
      });
    },
    // 卡片和列表模式切换
    changeTab(tab) {
      if (!this.readFileObj.is_dir) return false;
      this.showTab = this.showTab === 'list' ? 'card' : 'list';
    },
    getFile (node, index, e) {
      if (e) e.stopPropagation();
      if (index === this.fileRoute.length -1 || this.fileRoute.length === 1) return false
      this.fileRoute = this.fileRoute.splice(0, index + 1)
      this.selectFile = node
      this.getProxyFile(node, 'route')
    },
    // 返回上一级文件
    backFile (e) {
      if (e) e.stopPropagation();
      if (this.fileRoute.length === 1) return false
      this.fileRoute.pop()
      this.selectFile = this.fileRoute[this.fileRoute.length - 1]
      this.getProxyFile(this.fileRoute[this.fileRoute.length - 1], 'route')
    },
    getRequestFullPath () {
      let str = this.fileRoute[this.fileRoute.length -1].fullpath || '/'
      if (str.includes('//')) return str.replaceAll('//', '/')
      return str
    },
    arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      for (let i = 0; i < arr1.length; i++) {
        if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
          if (!this.deepEqual(arr1[i], arr2[i])) return false;
        } else if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    },
    deepEqual(o1, o2) {
      if (o1 === o2) return true;
      if (o1 == null || typeof o1 != 'object' || o2 == null || typeof o2 != 'object') return false;

      let keys1 = Object.keys(o1);
      let keys2 = Object.keys(o2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        if (!keys2.includes(key) || !this.arraysAreEqual(o1[key], o2[key])) return false;
      }

      return true;
    },
    // 获取当前目录下文件
    getProxyFile(file, type='file', getTypeFlag='file', uploadObj) {
      let refreshFlag = true
      if (uploadObj) {
        console.log(uploadObj, this.fileRoute, this.fileSystemId, 'wore')
        if (uploadObj.treeFileId !== this.fileSystemId || !this.arraysAreEqual(this.fileRoute, uploadObj.fileRoute)) refreshFlag = false
      }
      if (!refreshFlag) return false
      let node = file
      if (type === 'openFile') node = file.parentFile
      this.readFileObj.is_dir = true
      if (this.fileRequestLoading) return false
      this.showRoute = true
      if(!uploadObj || (uploadObj && uploadObj.type !== 'upload')) this.fileRequestLoading = true
      // 所有不是从搜索框进入的 都把搜索值清掉
      if (getTypeFlag !== 'search') {
        this.searchValue = ''
        this.tips = ''
      } else {
        this.tips = '正在检索中'
      }
      if (this.showTab === 'card') this.hasSearch = false
      if (['route'].includes(type)) node.fullpath = this.getRequestFullPath()
      let apiAjax = global.API.getProxyFile + `${node.fullpath}` + '?cluster_id=' + this.clusterId
      const params = {
        filesystem: this.winboxHost.showItem.id, // 取当前这个Tab页签的filesystemId
        keyword: this.searchValue
      };
      this.$axiosProxyGet(apiAjax, params).then((res) => {
        const result = res.data;
        this.fileRequestLoading = false
        if ([200, 201, 204].includes(res.status)) {
          let arr = node.parentArr ? _.cloneDeep(node.parentArr) : []
          // 初始化 
          if (['tree', 'openFile'].includes(type)) this.fileRoute = arr
          // 选中文件
          if (type === 'file') this.fileRoute.push({name: node.name, fullpath: node.fullpath, ...node, fileList: this.fileList.filter(child => child.is_dir)})
          // 根据路径输入
          if (type === 'input') this.handleInputChangeRoute(this.routePath)
          if (type === 'search') this.getFileRoute(node.fullpath)
          if  (type === 'dropdown') this.getFileRoute(node.fullpath, 'dropdown')
          this.fileList = []
          if (result && result.length > 0) {
            result.map((val) => {
              this.fileList.push({
                title: val.name,
                name: val.name,
                svgIcon: "file-dir",
                key: val.fullpath,
                isLeaf: !val.is_dir,
                id: val.fullpath,
                pid: node.isChild
                  ? node.pid
                  : node.id,
                isChild: true,
                parentArr: arr,
                scopedSlots: { title: 'host' },
                ...val,
              });
            });
          }
          if (type === 'openFile') this.openFile(file)
        } else {
          this.fileRequestLoading = false
        }
      }).catch(() => {
        this.fileRequestLoading = false
      })
    },
  },
};
</script>
<style scoped lang='less'>

.right-file-loading {
  height: 100%;
   /deep/ .ant-spin{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  /deep/ .ant-spin-container {
    height: 100%;
  }
}
.file-list {
  height: 100%;
  &-header {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #CFD5DE;
    &-left {
      display: flex;
      align-items: center;
      // padding: 4px 10px;
      flex: 1;
      border: 1px solid#CFD5DE;
      border-radius: 4px;
      margin-right: 10px;
      line-height: 20px;
      cursor: pointer;
      .route-item {
        display: flex;
        cursor: pointer;
        &-name {
          padding:0 6px;
          &:hover {
            background: rgb(229, 243, 255)
          }
        }
        &-selected {
          // color: #34343C;
          font-weight: 600;
        }
      }
      &-back {
        line-height: 30px;
        padding-right: 8px;
        padding-left: 10px;
        height: 30px;
        border-radius:4px 0 0 4px ;
        border-right: 1px solid #cfd5de;
        margin-right: 6px;
        cursor: pointer;
        svg {
          font-size: 15px;
        }
        &:hover {
          background: #cfd5de;
        }
      }
      .header-right {
        display: flex;
        padding: 4px 0px 4px 0;
        flex: 1;
      }
      .reload-icon {
        border-left: 1px solid #cfd5de;
        width: 34px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        &:hover {
          background: #cfd5de;
        }
      }
    }
    &-show-input {
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      .route-item {
        cursor: pointer;
        &-name {
          padding:0 6px;
          &:hover {
            background: rgb(229, 243, 255)
          }
        }
        &-selected {
          // color: #34343C;
          font-weight: 600;
        }
      }
      &-back {
        line-height: 16px;
        padding-right: 8px;
        height: 16px;
        border-right: 1px solid #cfd5de;
        margin-right: 6px;
        svg {
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
    &-right {
      display: flex;
      // width:604px;
      .file-show-type {
        display: flex;
        .show-warp {
          cursor: pointer;
          width: 34px;
          height: 30px;
          border: 1px solid rgba(186, 193, 201, 1);
          border-right: none;
          line-height: 28px;
          text-align: center;
          &:last-child {
            border-right: 1px solid rgba(186, 193, 201, 1);
          }
          &-card {
            // border-radius: 3px 0px 0px 3px;
            border-radius: 3px;
          }
          &-list {
            border-radius: 0 3px 3px 0px;
          }
        }
        .show-warp-seleted {
          border: 1px solid rgba(57, 116, 244, 1) !important;
          svg {
            color: #3974f4;
          }
        }
      }
      .height30 {
        height: 30px;
        background: #fff;
      }
      /deep/ .ant-input {
        height: 30px!important;
      }
    }
  }
  .select-warp {
    width: 100%;
    height: 40px;
    background: #F7F9FC;
  }
  &-body {
    height: calc(100% - 52px);
    max-height: calc(100% - 52px);
  }
}
.more-dir {
  .ant-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>