
<template>
  <div
    ref="dragArea"
    v-resize="handleResize"
    class="drop-zone"
    @dragover.prevent
    @dragenter.prevent
    @drop="handleDrop"
    :class="['file-card']"
    @mousedown="startDrag($event)"
    @mousemove="dragging($event)"
    @mouseup="endDrag($event)"
    @contextmenu.prevent="(e) => parentShow(e)"
  >
    <div
      class="file-card-container"
      :style="containerStyle"
    >
      <div
        v-if="isDragging"
        :style="rectangleStyle"
      ></div>
      <div
        v-for="(item, index) in visibleItems"
        :key="index"
        :ref="`file-${item.fullpath}`"
        :class="['file-item', isChecked(item) ? 'checked' : '', !treeVisible && 'big-file-item']"
        @contextmenu.prevent="(e) => show(e, item, index)"
        @click.prevent="getProxyFile($event, item)"
      >
        <div
          :class="['check-warp']"
          @click.stop="checkFile($event, item)"
        >
          <svg-icon
            v-if="isChecked(item)"
            icon-class="file-checked"
          />
        </div>
        <svg-icon
          v-if="item.is_dir"
          icon-class="file-item-dir"
        ></svg-icon>
        <svg-icon
          v-else
          :icon-class="getIcon(item.name)"
        ></svg-icon>
        <div
          class="file-name ell-more"
          :title="item.name"
        >{{ item.name }}</div>
        <div class="file-time">{{ fileTime(item) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import { mapState, mapMutations, mapActions } from "vuex";
import { UploadFile } from './upload.js'
export default {
  props: {
    treeFileId: [String, Number],
    treeFilePath: String,
    clusterId: [String, Number],
    fileRoute: Array,
    fileList: Array,
    fileSystemId: [String, Number],
    fileRequestLoading: Boolean,
    treeVisible: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visibleItems: [], // 当前可见的文件列表
      pageSize: 70, // 每页显示的数量
      currentPage: 1, // 当前页码
      isLoading: false, // 加载状态
      allDataLoaded: false, // 标志所有数据是否已加载完毕
      isFirst: true,
      selectCurrentFile: null,
      menuItems: [],
      isDir: true,
      checkFileList: [],
      isFullScreen: false,
      visible: false,
      code: '',
      file: {},
      lang: 'powershell',
      isDragging: false, // 是否正在拖拽
      startX: 0, // 鼠标按下时的坐标
      startY: 0, // 鼠标按下时的坐标
      endX: 0, // 鼠标松开时的坐标
      endY: 0, // 鼠标松开时的坐标
      isOptSelf: false,
      showRectangle: false,
      selectionItems: [], // 选中的元素
      fileCardContainer: {
        width: 0,
        height: 0
      },

    };
  },
  inject: ['windowPropsData', 'windowAppLoaderOptions'],
  mixins: [UploadFile],
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage,
      windowStore: (state) => state.windowStore,
    }),
    containerStyle () {
      let containerStyle = {
        width: '100%'
      }
      if (this.visibleItems.length * 150 < this.fileCardContainer.width && this.visibleItems.length > 0) {
        containerStyle = {
          width: this.visibleItems.length * 150 + 'px'
        }
      } else {
        containerStyle = {
          width: '100%'
        }
      }
      return containerStyle
    },
    rectangleStyle () {
      const winbox = this.windowStore.windowStore[this.windowAppLoaderOptions.id]
      const max = winbox?.window?.max
      const full = winbox?.window?.full
      let x = winbox?.window?.x
      let y = winbox?.window?.y
      if (max || full) {
        y = 0
        x = 0
      }
      if (!this.treeVisible) x = x - 241
      const width = Math.abs(this.endX - this.startX);
      const height = Math.abs(this.endY - this.startY);
      const left = this.endX > this.startX ? this.startX : this.endX;
      const top = this.endY > this.startY ? this.startY : this.endY;
      return {
        position: 'absolute',
        backgroundColor: '#3974f4',
        opacity: 0.2,
        border: '1px dashed #3974f4',
        width: `${width}px`,
        height: `${height}px`,
        left: `${left - x - 254}px`,
        top: `${top - y - 130}px`,
        zIndex: 100
      };
    }
  },
  components: {
  },
  mounted () {
    this.addScrollListener();
    this.scrollToTop()
    let start = (this.currentPage - 1) * this.pageSize
    const end = this.currentPage * this.pageSize
    let b = this.fileList.slice(start ,end)
    this.visibleItems = this.visibleItems.concat(b);
    console.log(this.visibleItems, 'this.visibleItems')
  },
  watch: {
    fileList: {
      handler (val, oldVal) {
        console.log(oldVal, 'fileList')
        if(!oldVal) return
        this.scrollToTop()
        let start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        let b = this.fileList.slice(start ,end)
        this.visibleItems = this.visibleItems.concat(b);
        console.log(this.visibleItems, 'this.visibleItems')
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("fileManage", [
      "changeShowFileProgress",
      "changeUploadFileList",
      "changeUpload"
    ]),
    handleDrop (event) {
      event.preventDefault(); // 阻止默认行为
      const files = []// 获取文件列表
      const items = event.dataTransfer.items || event.dataTransfer.files;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.webkitGetAsEntry) { // 对于文件夹的支持
          const entry = item.webkitGetAsEntry();
          if (entry.isDirectory) {
            this.isFolder = true;
            this.$message.warning('暂不支持文件夹上传！')
          }
          if (entry.isFile) {
              const file = item.getAsFile();
              files.push(file);
          }
        }
      }
      this.handleChange(files)
    },
    handleResize ({ width, height }) {
      this.fileCardContainer.width = width
    },
    startDrag (event) {
      if (event.button === 2) return false
      let num = 0
      this.visibleItems.forEach((item, index) => {
        const refName = `file-${item.fullpath}`;
        const element = this.$refs[refName][0];
        if (element.contains(event.target)) {
          num++
        }
      })
      if (num > 0) {
        this.isOptSelf = true
        this.isDragging = false
      } else {
        this.isDragging = true;
        this.startX = event.clientX;
        this.startY = event.clientY;
        this.endX = event.clientX;
        this.endY = event.clientY;
        this.isOptSelf = false
      }
      // 当前鼠标点击的不在子元素上清空选中的元素
      if (!this.isOptSelf) this.checkFileList = [];
    },
    dragging (event) {
      if (!this.isDragging) {
        return;
      }
      this.isOptSelf = false
      this.endX = event.clientX;
      this.endY = event.clientY;
      const x1 = this.startX > this.endX ? this.endX : this.startX
      const x2 = this.startX > this.endX ? this.startX : this.endX
      const y1 = this.startY > this.endY ? this.endY : this.startY
      const y2 = this.startY > this.endY ? this.startY : this.endY
      this.checkFileList = []
      // 遍历所有元素，判断是否在选择区域内      
      this.visibleItems.forEach((item, index) => {
        const refName = `file-${item.fullpath}`;
        const element = this.$refs[refName][0];
        const rect = element.getBoundingClientRect();
        const m1 = rect.left;
        const n1 = rect.top;
        const m2 = rect.right;
        const n2 = rect.bottom;
        const selected = !(x1 > m2 || x2 < m1 || y1 > n2 || y2 < n1);
        // 如果元素被选中，则将其添加到选中的元素列表中
        const currentIndex = this.checkFileList.findIndex(file => file.fullpath === item.fullpath) || -1
        if (selected) {
          this.checkFileList.push(item);
        } else {
          this.checkFileList.splice(index, 1);
        }
      });
    },
    endDrag (event) {
      this.isDragging = false;
    },
    handleCancel () {
      this.visible = false
    },
    selectAll () {
      this.checkFileList = []
      this.visibleItems.map(item => {
        this.checkFileList.push(item)
      })
    },
    parentShow (e) {
      e.preventDefault();
      e.stopPropagation()
      let items = [
        {
          label: "终端",
          icon: 'contextmenu-cmd',
          onClick: () => {
            e.stopPropagation()
            this.$emit('openSsh', e)
          },
        },
        {
          label: "新建",
          icon: 'file-add',
          onClick: () => {
            this.$emit('mkdir')
          },
        },
        {
          label: "上传",
          icon: 'contextmenu-upload',
          onClick: () => {
            this.$emit('handleUploadClick')
          },
        }
      ]
      if (this.selectCurrentFile) {
        items.unshift(
          {
            icon: 'contextmenu-rename',
            label: "重命名",
            onClick: () => {
            },
          }
        )
        if (!this.selectCurrentFile.is_dir) {
          items.unshift({
            icon: 'contextmenu-open',
            label: "打开",
            onClick: () => {
              this.$emit('openFile', this.selectCurrentFile)
            },
          })
        }
      }
      if (this.checkFileList.length) {
        let fileNum = 0
        this.checkFileList.map(item => {
          if (!item.is_dir) fileNum++
        })
        items.push(
          {
            label: "删除",
            icon: 'contextmenu-del',
            onClick: () => {
              this.$emit('deleteFile')
            },
          },
        )
        // 只有选中的有文件才给下载
        if (fileNum > 0) {
          items.splice(2, 0, {
            label: "下载",
            icon: 'contextmenu-down',
            onClick: () => {
              this.$emit('downFile')
            },
          })
        }
      }
      items.push({
        label: "全选",
        icon: 'contextmenu-selectall',
        onClick: () => {
          this.selectAll()
        },
      })
      items.push({
        label: "刷新",
        icon: 'contextmenu-refresh',
        onClick: () => {
          this.$emit('refreshFile', e)
        },
      })
      this.selectCurrentFile = null
      this.$contextmenu({
        items: items,
        event: e,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    show (event, item, index) {
      this.selectCurrentFile = item
      if (this.checkFileList.length > 0) return false
      event.stopPropagation()
      let items = [
        {
          icon: 'contextmenu-rename',
          label: "重命名",
          onClick: () => {
          },
        },
        {
          icon: 'contextmenu-del',
          label: "删除",
          onClick: () => {
            this.$emit('deleteFile', item)
          },
        },
      ]
      if (!item.is_dir) {
        items.splice(2, 0, {
          label: "下载",
          icon: 'contextmenu-down',
          onClick: () => {
            this.$emit('downFile', item)
          },
        })
        items.unshift({
          icon: 'contextmenu-open',
          label: "打开",
          onClick: () => {
            this.$emit('openFile', item)
          },
        })
        items.unshift({
          icon: 'contextmenu-open',
          label: "编辑",
          onClick: () => {
            this.$emit('editFileByOnlyOffice', item)
          },
        })
      }
      this.$contextmenu({
        items: items,
        event,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    getEdit (file) {
      if (this.fileRequestLoading) return false
      this.$emit('changeFileRequestLoading', true)
      // 文件夹不行
      if (file.is_dir) return false;
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      // if (!arr.length) return false
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // if(!['txt', 'go', 'js', 'vue', 'yaml', 'sh', 'java', 'log', 'md', 'sql', 'html'].includes(key)) return false
      // if ((file.size / 1048576) > 2) {
      //   this.$emit('changeFileRequestLoading', false)
      //   return this.$message.warning('文件大小超过2M，请使用其它编辑方式')
      // }
      this.$emit('readFile', 1, '文件读取中')
      const self = this;
      const ajaxApi = global.API.downloadProxyFile
      this.$emit('changeFileRequestLoading', true)
      this.$axiosProxyDown(ajaxApi + file.fullpath, { filesystem: this.fileSystemId }).then((res) => {
        this.$emit('changeFileRequestLoading', false)
        this.$emit('readFile', 0, '')
        if ([200, 201, 204].includes(res.status)) {
          let code = res.data
          this.lang = 'powershell'
          if (Object.prototype.toString.call(code) === '[object Object]') {
            code = JSON.stringify(code, null, " ");
          }
          this.code = code
          this.file = file
          this.isDir = false
        }
      }).catch(res => {
        this.$emit('changeFileRequestLoading', false)
        this.$emit('readFile', 0, '')
        this.$message.error('当前文件获取内容失败')
      })
    },
    changeFull (val) {
      this.isFullScreen = val === 'big'
    },
    checkFile (e, file) {
      this.isOptSelf = true;
      e.stopPropagation()
      e.preventDefault()
      const index = this.checkFileList.findIndex(
        (item) => item.fullpath === file.fullpath
      );
      if (index > -1) {
        this.checkFileList.splice(index, 1);
      } else {
        this.checkFileList.push(file);
      }
    },
    isChecked (file) {
      const index = this.checkFileList.findIndex(
        (item) => item.fullpath === file.fullpath
      );
      return index > -1;
    },
    getProxyFile (event, val) {
      this.isOptSelf = true;
      event.stopPropagation()
      event.preventDefault()
      if (this.fileRequestLoading) return false;
      this.checkFileList = []
      if (val.is_dir) return this.$emit("onGetProxyFile", val);
      if (global.utils.isPreviewFile(val)) return this.$emit('openFile', val)
      this.$emit('getEdit', val)
    },
    fileTime (item) {
      return moment(item.mod_time).format("MM/DD HH:mm");
    },
    getIcon (name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileManageIcon(key)
        ? global.utils.mapFileManageIcon(key)
        : "file-question2";
    },
    // 前端分页
    handleScroll() {
      if (this.visibleItems.length === this.fileList.length) this.allDataLoaded = true
      const container = this.$refs.dragArea;
      if (!container || this.loadingMore || this.allDataLoaded) return;
      console.log('滚动中',  this.visibleItems.length, this.fileList.length);
      // 检查是否滚动到底部
      const isAtBottom = container.scrollTop >= container.scrollHeight -  container.clientHeight;

      if (isAtBottom && !this.loadingMore) {
        this.loadMoreItems();
      }
    },

    loadMoreItems() {
      if (this.loadingMore || this.allDataLoaded) return;
      
      this.loadingMore = true;
      console.log('触发加载更多');

      // 模拟异步加载更多数据
      this.currentPage++
      let start = (this.currentPage - 1) * this.pageSize
      const end = this.currentPage * this.pageSize
      let b = this.fileList.slice(start ,end)
      this.visibleItems = this.visibleItems.concat(b);
      console.log(this.visibleItems.length, this.fileList.length, 'this.visibleItems', start, end)
      this.loadingMore = false;
    },
    scrollToTop() {
      const container = this.$refs.dragArea;
      if (container) {
        container.scrollTop = 0;
        this.currentPage = 1
        this.loadingMore = false
        this.allDataLoaded = false
        this.visibleItems  = []
      }
    },
    
    addScrollListener() {
      const container = this.$refs.dragArea;
      if (container) {
        container.addEventListener('scroll', this.handleScroll);
      }
    },

    removeScrollListener() {
      const container = this.$refs.templateList;
      if (container) {
        container.removeEventListener('scroll', this.handleScroll);
      }
    },
  },
  beforeDestroy () {
    this.removeScrollListener()
  },
};
</script>
<style scoped lang='less'>
.file-card {
  max-height: calc(100% - 10px);
  height: calc(100% - 10px);
  overflow-y: auto;
  user-select: none;
  align-items: flex-start;
  padding-top: 10px;
  padding-right: 10px;
  &-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  .file-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    max-width: 130px;
    // min-width: 130px;
    border: 1px solid rgba(230, 233, 235, 1);
    border-radius: 4px;
    margin: 0 0px 30px 20px;
    padding: 0 10px;
    cursor: pointer;
    .check-warp {
      display: none;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid rgba(196, 202, 211, 1);
      border-radius: 2px;
    }
    &:hover {
      background: rgba(232, 240, 253, 0.9);
      border: 1px solid rgba(212, 225, 245, 1);
      border-radius: 4px;
      .check-warp {
        display: block;
      }
    }
    svg {
      font-size: 80px;
    }
    .file-name {
      font-size: 14px;
      color: #1d202d;
      text-align: center;
      font-weight: 400;
      margin: 0px 0 4px;
      width: 100%;
    }
    .file-time {
      font-size: 14px;
      color: #a4a6a7;
      font-weight: 400;
    }
  }
  .big-file-item {
    // width: 7.95%;
  }
  .checked {
    background: rgba(232, 240, 253, 0.9);
    border: 1px solid rgba(212, 225, 245, 1);
    border-radius: 4px;
    .check-warp {
      display: block;
      background: #3974f4;
      svg {
        color: #fff;
        z-index: 1;
        font-size: 16px;
        position: relative;
        top: -3px;
        right: 1px;
      }
    }
  }
}
</style>