<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-06 14:55:25
 * @LastEditTime: 2025-03-22 12:45:02
 * @FilePath: \awx-ui\src\pages\groupManage\groupTree.vue
-->
<template>
  <div class="left-tree" :style="{ height: 'calc(100% - 0px)' }">
    <div class="sider-header-button">
      <a-input style="width: calc(100% - 60px);" :value="searchValue"  @pressEnter="changeSearchValue" placeholder="输入主机名称检索">
      <svg-icon slot="suffix" icon-class="realtime-search" />
    </a-input>
      <div style="min-width: 45px;">
        <a-tooltip
          placement="bottom"
          :title="item.title"
          arrow-point-at-center
          v-for="(item) in treeTopList" :key="item.key"
        >
          <a-button
            :icon="item.icon"
            class="sider-header-btn"
            style="font-size:14px;border-width: 0px;background:rgba(0,0,0,0);width: 20px;"
            @click="(event) => clickPopCommon(item)"
          />
          
        </a-tooltip>
      </div>
    </div>
    <a-spin :spinning="treeSpain" class="left-tree-container">
      <div :class="'sysDevStyle'" style="overflow-y: auto; padding: 0 10px">
        <a-tree :treeData="treeData" class="a-tree-local-layout" @expand="onExpand" :key="onlyTreeKey" :expandedKeys="expandedKeys" :autoExpandParent="autoExpandParent" :selectedKeys="selectedKeys" :load-data="onLoadData" defaultExpandAll @select="onSelect">
          
          <template slot="host" slot-scope="item">
            <div :class="['local-tree-custom']">
              <template>
                <span style="margin: 0 4px 1px">
                  <svg-icon v-if="item.treeIconType === 'host'" style="font-size: 16px" icon-class="group-host" />
                  <svg-icon v-if="item.treeIconType === 'group' && !item.isLeaf && item.expanded"  style="font-size: 16px" icon-class="systemrootdriveopen" />
                  <svg-icon v-if="item.treeIconType === 'group' && !item.isLeaf && !item.expanded" style="font-size: 16px" icon-class="systemrootdrive" />
                  <svg-icon v-if="item.treeIconType === 'file' && !item.isLeaf && item.expanded"  style="font-size: 16px" icon-class="tree-dir-open" />
                  <svg-icon v-if="item.treeIconType === 'file' && !item.isLeaf && !item.expanded"  style="font-size: 16px" icon-class="tree-dir" />
                  <svg-icon v-if="item.treeIconType === 'file' && item.isLeaf" style="font-size: 16px" :iconClass="getIcon(item.title)" />
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">
                <span>{{ item.title }}</span>
                <span v-if="item.treeType === 'group' && !item.key.includes('groupAllHost') && item.key !== 'master'">({{ item.host_count || 0}}) </span>
              </span>
              <span v-if="item.pid === 0 && !item.key.includes('groupAllHost') && item.key !== 'master'" class="tree-more hide-point" style="width: 10px; text-align: right">
                <div @click.stop>
                  <a-popover placement="rightTop" class="popover-index" overlayClassName="popover-index" :content="() => getMoreMenu(item)">
                    <a-icon type="more" @click="showPopover(item)" />
                  </a-popover>
                </div>
              </span>
            </div>
          </template>
        </a-tree>
      </div>
    </a-spin>
    <!-- <a-input style="width: calc(100% - 30px); padding-left: 30px;" :value="searchValue"  @pressEnter="changeSearchValue" placeholder="输入主机名称检索">
      <svg-icon slot="suffix" icon-class="realtime-search" />
    </a-input> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SvgIcon from '@/icons/SvgIcon.vue';
import { debounce, throttle } from 'lodash'
import { MixinFile } from './fileTree.js'
import { MixinHost } from './hostTree.js'


function generateUUID() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
export default {
  components: { SvgIcon },
  props: {
    masterList: Array
  },
  data() {
    return {
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: [],
      expandedKeys: [],
      treefilterData: [],
      cluster_id: undefined,
      treeData: [],
      treeSpain: false,
      onlyTreeKey: 0,
      treeTopList:[
        { title: "添加主机", key: "addGroup", path: "/", icon: "plus" },
        { title: "刷新", key: "sync", path: "/", icon: "sync" },
      ],
    };
  },
  mounted() {
  },
  inject: ["windowEventBus", "windowAppLoaderOptions"],
  mixins: [MixinFile,MixinHost],
  watch: {
    '$route': {
      handler (val) {
        // console.log(val, 'route')
      },
      immediate: true
    },
    // 集群ID是全局的不需要个窗口走 所以这个 不需要取 winboxHost
    cluster_id: {
      handler (val) {
        if (val) {
        }
      },
      immediate: true
    },
    masterList: {
      handler(val) {
        if (val && val.length) {
          this.cluster_id = localStorage.getItem('CLUSTER_ID')
          this.convertTreeData();
        }     
      },
      deep: true
    },
    searchValue: {
      handler(val) {
        this.setSearchValue(val)
        this.setWinboxSearchValue({id: this.currentWinboxKey, value: val});
      }
    }
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
      winboxHostModule: (state) => state.winboxHostModule,
    }),
    // 当前winbox的ID
    currentWinboxKey () {
      return this.windowAppLoaderOptions.id
    },
    winboxHost () {
      return this.winboxHostModule[this.currentWinboxKey]
    },
    masterOptions () {
      const options = localStorage.getItem('masterOptions')
      const masterOptions = (options && options.length) ? JSON.parse(options) : []
      return masterOptions
    }
  },
  methods: {
    ...mapActions("fileManage", [
      "changeRealEditList",
      "setFileRequestLoading",
      "setSysType",
      "changeShowItem",
      "setClusterId",
      "setSelectTreeFlag",
      "setSearchValue",
    ]),
     ...mapActions("winboxHostModule", [
      "changeWinboxRealEditList",
      "changeWinboxShowItem",
      "setWinboxSelectTreeFlag",
      "setWinboxSearchValue",
    ]),
    clickPopCommon(item) {
      if (item.key == "addGroup") {
        this.addMenu();
      }
      if (item.key == "sync") {
        // this.convertTreeData();
        this.$emit('getMaster')
      }
    },
    getIcon(name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileTreeIcon(key)
        ? global.utils.mapFileTreeIcon(key)
        : "tree-question2";
    },
    createServices () {
      this.$emit('createServices', {})
    },
    onExpand(expandedKeys, { node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    changeSearchValue(val){
      this.searchValue = val.target.value
      this.convertTreeData('searchHost')
    },
    disTreeData(data,_expandedKeys){
      if(data && data.length>0){
        data.forEach(item => {
          if(_expandedKeys && _expandedKeys.indexOf(item.key) < 0){
            item['style'] = {display: 'none'};
          }else{
            item['style'] = {display: 'block'};
          }
          if(item.children && item.children.length != 0){
            this.disTreeData(item.children,_expandedKeys)
          } 
        });
      }
    },
    // 重命名的时候将Tab里面对应的作业名称修改
    editByRename (data, nodeName) {
      if (this.winboxHost.realEditList.length > 0) {
        let realEditList = _.cloneDeep(this.winboxHost.realEditList)
        let index = realEditList.findIndex(item => item.key === data.key)
        if (index !== -1) {
          realEditList[index].name = nodeName
          realEditList[index].nodeName = nodeName
          realEditList[index].title = nodeName
          realEditList[index].index = index
          this.changeShowItem(realEditList[index])
          this.changeRealEditList(realEditList);
          this.changeWinboxShowItem({id: this.currentWinboxKey, value:realEditList[index]});
          this.changeWinboxRealEditList({id: this.currentWinboxKey, value:realEditList});
        }
      }
    },
    delByRemoveTree (node) {
      const realEditList = _.cloneDeep(this.winboxHost.realEditList)
      const currentIndex = realEditList.findIndex(item => item.key === node.key)  
      if (currentIndex > -1) {
        let bool = this.removeTable(currentIndex)
      }
    },
    // 根据集群展开获取主机分组
    async getGroups(treeNode, treeType) {
      this.treeSpain = true;
      const params = {
        host_name: this.searchValue,
        page_size: 1000,
        cluster_id: treeNode.dataRef?.id,
      };
      try {
        const res = await this.$axiosGet(global.API.getHostGroups, params);
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            let arr = [];
            let defaultArr = res.data.results.filter(item => item.name === 'default');
            if (defaultArr.length > 0) {
              arr = await this.getHostByGroup(treeNode, treeType, defaultArr[0]);
            }
            let otherArr = res.data.results.filter(item => item.name !== 'default');
            otherArr.forEach((item) => {
              item.path = "/";
              item.fullpath = "/";
              const pathArr = item.path.split("/").filter((child) => child);
              let path = "";
              const parentArr = [];
              pathArr.forEach((child) => {
                path = path + "/" + child;
                parentArr.push({
                  ...item,
                  fullpath: path,
                  path: "/",
                  name: child,
                  scopedSlots: { title: "host" },
                  svgIcon: "tree-dir",
                  treeIconType: "group",
                });
              });
              const childNode = {
                name: item.name,
                isLeaf: false,
                key: "group-" + treeNode.dataRef?.id + '-' + item.id,
                pid: treeNode.dataRef?.key,
                treeType: "group",
                treeIconType: "group",
                svgIcon: "systemrootdrive",
                title: item.name,
                children: [],
                parentArr: parentArr,
                path: "/",
                ...item,
                scopedSlots: { title: "host" },
              };
              arr.push(childNode);
            });
            treeNode.dataRef.children = arr;
          }
        }
      } catch (err) {
        this.treeSpain = false;
        console.error('获取主机组数据出错:', err);
      }
    },
    async getHostByGroup (treeNode, treeType, defaultGroup) {
      if (defaultGroup) {
        treeNode.dataRef.treeType = 'host'
        treeNode.dataRef.parentArr = []
      }
      let apiAjax =  global.API.getHostGroups + `/${defaultGroup.id}/hosts`
      const params = {
        page_size: 10000,
        cluster_id: treeNode.dataRef.clusterId
      }
      if (this.searchValue) params.keyword = this.searchValue
      const pid = defaultGroup.id
      try {
        const res = await this.$axiosProxyGet(apiAjax, params)
        if ([200, 201, 204].includes(res.status)) {
          const result = treeNode.dataRef.treeType === 'file' ? res.data : res.data.results
          if (result && result.length > 0) {
            return result.map((val) => ({
              title: val.name,
              name: val.name,
              svgIcon: treeNode.dataRef.treeType === 'file' ? 'tree-dir' : 'group-host',
              key: pid + (treeNode.dataRef.treeType === 'file' ? val.fullpath : '-' + val.id),
              isLeaf: treeNode.dataRef.treeType === 'file' ? !val.is_dir : false,
              id: treeNode.dataRef.treeType === 'file' ? val.fullpath : val.id,
              treeType: 'file',
              master: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.clusterId,
              cluster_id: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.clusterId,
              treeIconType: treeNode.dataRef.treeType === 'file' ? 'file' : 'host',
              fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
              path: treeNode.dataRef.treeType === 'file' ? val.path : '/',
              pid: pid,
              isChild: treeNode.dataRef.treeType === 'file',
              parentArr: treeNode.dataRef.parentArr.concat([
                {
                  fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
                  ...val,
                  name: treeNode.dataRef.treeType === 'file' ? val.name : '根目录',
                }
              ]),
              scopedSlots: { title: 'host' },
              ...val,
            }))
          }
        }
        return []
      } catch (error) {
        console.error('获取主机组数据出错:', error)
        return []
      }
    },
    // 动态获取文件树
    onLoadData (treeNode, treeType) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.key.includes('groupAllHost')) {
          this.getGroups(treeNode, treeType)
          resolve();
          return;
        }
        if (treeNode.dataRef.children && treeNode.dataRef.children.length) {
          resolve();
          return;
        }
        let apiAjax = treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.isChild ?  global.API.getProxyFile + `${treeNode.dataRef.fullpath}` :  global.API.getProxyFile + `${treeNode.dataRef.path}` : global.API.getHostGroups + `/${treeNode.dataRef.id}/hosts`
        const params = {
          page_size: 10000
        }
        params.cluster_id = treeNode.dataRef.cluster_id || treeNode.dataRef.master
        if (treeNode.dataRef.treeType === 'file')  { 
          params.filesystem = treeNode.dataRef.isChild ? treeNode.dataRef.pid : treeNode.dataRef.id 
          params.cluster_id = treeNode.dataRef.parentArr && treeNode.dataRef.parentArr.length ? treeNode.dataRef.parentArr[0].master : ''
        } 
        if (this.searchValue) params.keyword  = this.searchValue
        const pid = treeNode.dataRef.key.includes('groupAllHost')  ? treeNode.dataRef.group_id : treeNode.dataRef.isChild ? treeNode.dataRef.pid : treeNode.dataRef.id
        this.$axiosProxyGet(apiAjax, params).then((res) => {
          const result = treeNode.dataRef.treeType === 'file' ?  res.data : res.data.results
          if ([200, 201, 204].includes(res.status)) {
            if (result && result.length > 0) {
              const treeNodeData = result
              treeNode.dataRef.children = treeNodeData.map((val) => {
                return {
                  title: val.name,
                  name: val.name,
                  svgIcon: treeNode.dataRef.treeType === 'file' ? 'tree-dir' : 'group-host',
                  key: pid + (treeNode.dataRef.treeType === 'file' ? val.fullpath : '-' + val.id),
                  isLeaf:  treeNode.dataRef.treeType === 'file' ? !val.is_dir : false,
                  id: treeNode.dataRef.treeType === 'file' ? val.fullpath : val.id,
                  treeType: 'file',
                  master: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.cluster_id,
                  cluster_id: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.cluster_id,
                  treeIconType: treeNode.dataRef.treeType === 'file' ? 'file' : 'host',
                  fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
                  path: treeNode.dataRef.treeType === 'file' ? val.path : '/',
                  pid: pid,
                  isChild: treeNode.dataRef.treeType === 'file',
                  parentArr: treeNode.dataRef.parentArr.concat( [
                    {
                      fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
                      ...val,
                      name: treeNode.dataRef.treeType === 'file' ? val.name : '根目录',
                    }
                  ]),
                  scopedSlots: { title: treeNode.dataRef.scopedSlots.title },
                  ...val,
                };
              });
              treeNodeData.map((val) => {
                const childNode =  {
                  title: val.name,
                  name: val.name,
                  svgIcon: treeNode.dataRef.treeType === 'file' ? 'tree-dir' : 'group-host',
                  key: pid + (treeNode.dataRef.treeType === 'file' ? val.fullpath : '-' + val.id),
                  isLeaf:  treeNode.dataRef.treeType === 'file' ? !val.is_dir : false,
                  id: treeNode.dataRef.treeType === 'file' ? val.fullpath : val.id,
                  pid: pid,
                  master: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.cluster_id,
                  cluster_id: treeNode.dataRef.treeType === 'file' ? treeNode.dataRef.master : treeNode.dataRef.cluster_id,
                  path: treeNode.dataRef.treeType === 'file' ? val.path : '/',
                  fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
                  isChild: treeNode.dataRef.treeType === 'file',
                  treeType: 'file',
                  treeIconType: treeNode.dataRef.treeType === 'file' ? 'file' : 'host',
                  parentArr: treeNode.dataRef.parentArr.concat([
                    {
                      fullpath: treeNode.dataRef.treeType === 'file' ? val.fullpath : '/',
                      ...val,
                      name: treeNode.dataRef.treeType === 'file' ? val.name : '根目录',
                    }
                  ]),
                  scopedSlots: { title: treeNode.dataRef.scopedSlots.title },
                  ...val,
                };
                // this.treefilterData.push(childNode)
              });
              this.treeData = [...this.treeData];
              if (treeType === 'group') {
                // 打开主机租的时候展开
                // this.expandedKeys = [...this.expandedKeys, treeNode.dataRef.key]
              }
            }
            resolve();
          } else {
            resolve();
          }
        }).catch(res => {
          this.treeSpain = false
          console.log(res, '错误信息')
          resolve()
        })
      });
    },
    
    convertTreeData(type, node) {
      if (type !== 'searchHost') this.searchValue = ''
      this.debounceTreeData(type, node)
    },
    debounceTreeData: throttle(function(type, node) {
      this.selectedKeys = []
      this.expandedKeys = []
      this.onlyTreeKey++
      this.convertFileTreeData(type, node, this.masterList)
    }, 1000),

    showPopover(item) {
      this.popoverVisible = true;
    },
    destroyAll() {
      this.$destroyAll();
    },
    getMoreMenu(props) {
      const { isLeaf, expanded, type, editStatus, name, dataRef, pNodeId, treeType } =
        props;
      let arr = [];
      arr = [
        { name: "编辑", key: "edit" },
        { name: "删除", key: "delete" },
      ];
      return arr.map((item, index) => {
        return (
          <div key={index}>
            <a-button
              class="more-menu-btn"
              style="border-width:0px;min-width:100px;"
              onClick={() => {
                treeType === 'file' ? 
                  this.showFilePopDetail(item.key, props)
                  : this.showHostPopDetail(item.key, props)
              }}
            >
              {item.name}
            </a-button>
          </div>
        );
      });
    },
    onSelect(keys, { node }) {
      if (keys && keys.length > 0 && keys[0].includes('groupAllHost')) this.cluster_id = node?.dataRef.id
      // 这个地方把选择的cluterId记录进去
      if (node.dataRef.treeType === 'file') {
        this.onSelectFileTree(keys, node)
      } else {
        this.onSelectHostTree(keys, node)
      }
    },
    clickTab(key) {
      this.currentTabsKey = key;
    },
    pushTable(val) {
      let bool = true;
      let selectItemKey =
        val.index || this.winboxHost.realEditList.length;
      this.winboxHost.realEditList.map((item, index) => {
        if (
          (val.key && val.key== item.key)
        ) {
          bool = false;
          selectItemKey = index;
        }
      });
      if (bool) {
        let json = {
          index: selectItemKey,
          uuid: generateUUID(),
          ...val,
          tabselectKey: this.selectedKeys[0]
        };
        const openJosn = _.cloneDeep(json);
        const list = _.cloneDeep(this.winboxHost.realEditList);
        //记录当前打开页面
        this.winboxHost.showItem = openJosn;
        this.changeShowItem(openJosn);
        list.push(openJosn);
        this.changeRealEditList(list);
        this.changeWinboxShowItem({id: this.currentWinboxKey, value: openJosn});
        this.changeWinboxRealEditList({id: this.currentWinboxKey, value:list});
        if (val?.group_id && val?.svgIcon === 'group-host') {
          this.expandedKeys = this.expandedKeys.concat(['group-'+ val.master + '-' + val.group_id])
        }
      } else {
        this.winboxHost.showItem = {
          index: selectItemKey,
          ...this.winboxHost.realEditList[selectItemKey],
          tabselectKey: this.selectedKeys[0],
          activeKey: val.activeKey
        };
        this.changeShowItem(this.winboxHost.showItem);
        this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
        const list = _.cloneDeep(this.winboxHost.realEditList);
        list.forEach((item) => {
          if (item.key === this.winboxHost.showItem.key){
            item.tabselectKey = this.selectedKeys[0]
          }
        })
        this.changeRealEditList(list);
        this.changeWinboxRealEditList({id: this.currentWinboxKey, value:list});
      }
    },
    removeTable(index) {
      const realEditList = _.cloneDeep(this.winboxHost.realEditList)
      let bool = false;
      // 每一次关闭之后 如果后面有标签打开后面一个 如果已经是最后一个  打开前面一个
      if (realEditList.length > 0) {
        // 这个是直接关闭当前这个tab
        this.winboxHost.realEditList.splice(index, 1);
        this.changeRealEditList(this.winboxHost.realEditList);
        this.changeWinboxRealEditList({id: this.currentWinboxKey, value: this.winboxHost.realEditList});
        // 这个是关闭的标签刚好是打开的这个tab
        if (index == this.winboxHost.showItem.index) {
          // 如果已经是最后一个  打开前面一个
          if (index === realEditList.length - 1) {
            this.winboxHost.showItem = {
              ...realEditList[index - 1],
              index: index - 1,
            };
          } else {
            // 如果不是是最后一个  打开当前的后面的一个
            this.winboxHost.showItem = {
              ...this.winboxHost.realEditList[index],
              index: index,
            };
          }
        } else if (index < this.winboxHost.showItem.index) {
          this.winboxHost.showItem.index--;
        }
        bool = true;
      }
      if (this.winboxHost.realEditList.length === 0) this.winboxHost.showItem = {
        index:0
      }
      this.selectedKeys = [this.winboxHost.showItem.key]
      this.changeShowItem(this.winboxHost.showItem);
      this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
      return bool;
    },
    // 右鍵菜单操作
    delOption(e) {
      // 因为前面多了一个所有主机这个独立的不可关闭的tab
      // e.index = e.index - 1
      if (e.type === "other") {
        this.winboxHost.realEditList = [this.winboxHost.realEditList[e.index]];
        this.winboxHost.showItem = {
          ...this.winboxHost.realEditList[0],
          index: 0,
        };
        this.selectedKeys = [this.winboxHost.showItem.key];
        this.changeShowItem(this.winboxHost.showItem);
        this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
      }
      if (e.type === "left") {
        this.winboxHost.realEditList.splice(0, e.index);
        if (e.index + 1 < this.winboxHost.showItem.index) {
          this.winboxHost.showItem = {
            ...this.winboxHost.realEditList[
              this.winboxHost.showItem.index - e.index - 1
            ],
            index: this.winboxHost.showItem.index - e.index,
          };
        } else {
          this.winboxHost.showItem = {
            ...this.winboxHost.realEditList[0],
            index: 0,
          };
        }
        this.selectedKeys = [this.winboxHost.showItem.key];
        this.changeShowItem(this.winboxHost.showItem);
        this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
      }
      if (e.type === "right") {
        this.winboxHost.realEditList.splice(
          e.index + 1,
          this.winboxHost.realEditList.length
        );
        if (e.index + 1 > this.winboxHost.showItem.index) {
          // 
        } else {
          this.winboxHost.showItem = {
            ...this.winboxHost.realEditList[
              this.winboxHost.realEditList.length - 1
            ],
            index: this.winboxHost.realEditList.length,
          };
          this.selectedKeys = [this.winboxHost.showItem.key];
          this.changeShowItem(this.winboxHost.showItem);
          this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
        }
      }
      this.changeRealEditList(this.winboxHost.realEditList);
      this.changeWinboxRealEditList({id: this.currentWinboxKey, value: this.winboxHost.realEditList});

    },
    delAll() {
      this.changeRealEditList([]);
      this.changeWinboxRealEditList({id: this.currentWinboxKey, value: []});

      this.selectedKeys = [];
    },
    changeTable(e, setActiveKeyFile) {
      if (e.index == this.winboxHost.showItem.index) {
        return;
      }
      let item = e.item || {};
      this.winboxHost.showItem = {
        ...e.item,
        index: e.index,
      };
      this.setSelectTreeFlag(setActiveKeyFile === 'setActiveKeyFile')
      this.setWinboxSelectTreeFlag({id: this.currentWinboxKey, value: setActiveKeyFile === 'setActiveKeyFile'});
      const realList = _.cloneDeep(this.winboxHost.realEditList)
      realList[e.index] = this.winboxHost.showItem 
      this.changeShowItem(this.winboxHost.showItem);
      this.changeWinboxShowItem({id: this.currentWinboxKey, value: this.winboxHost.showItem});
      this.changeRealEditList(realList)
      this.changeWinboxRealEditList({id: this.currentWinboxKey, value: realList});
      // 左侧树选中
      this.selectedKeys = [].concat(item.tabselectKey + "");
      if (item.treeType === 'file') {
        const masterKey = 'groupAllHost-' + item.master
        const groupKey = 'group-'+ item.master + '-' + item.group_id
        this.expandedKeys = [masterKey, groupKey]
      } else {
        this.expandedKeys = [item.pid]
      }
    },
    clickDelIcon(e) {
      let self = this;
      // 删除当前编辑页面
      let bool = this.removeTable(e.index);
      // 重新设置默认打开页面 ==
      if (bool) {
        this.$destroyAll();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.curpon {
  cursor: pointer;
}
// 右边的气泡
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
  /deep/ .ant-popover-arrow {
    top: 20px !important;
    left: 20px !important;
  }
  .ant-popover-inner-content .ant-btn:hover,
  .ant-popover-inner-content .ant-btn:focus {
    background-color: #f4f5f7;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background: #2872e0 !important;
    color: #ffffff !important;
  }
}
.left-tree {
  // 搜索框
  .sider-header-button {
    padding: 20px 8px 5px 0;
    display: flex;
    .ant-input-search {
      margin-left: 16px;
      margin-right: 16px;
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 6px;
      }
    }
    .ant-input-affix-wrapper {
      margin-left: 12px;
      margin-right: 4px;
      margin-left: 24px;
    }
    .ant-input {
      border-radius: 2px;
      margin: 0;
      // background-color: #F2F4F7;
      // border-width:0px
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
    .sider-header-btn {
      box-shadow: none;
    }
  }
  .left-tree-container {
    .sysDevStyle {
      height: calc(100% - 35px) !important;
      overflow-y: scroll;
      .a-tree-local-layout {
        // padding: 0 14px 0 0px;

        .ant-tree li ul li {
          padding: 5px 0 5px 16px;
          border-bottom: 1px solid #f6f7fb;
        }
        .ant-tree li ul:not(.ant-tree-child-tree-open) {
          padding: 5px 0 5px 16px;
        }
        .ant-tree-child-tree-open {
          padding: 0;
        }
        .ant-tree-treenode-selected:not(.ant-tree-treenode-switcher-open) {
          background: #cce1fe;
          border-radius: 4px;
          .local-tree-content {
            // font-size: 14px;
            color: #0264c8 !important;
            letter-spacing: 0;
            font-weight: 400;
          }
          .popover-index {
            color: #0181ff;
          }
        }
        .ant-tree-treenode-switcher-close {
          // background-color: #fff !important;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% -24px) !important;
          padding: 0 10px 0 0 !important;
          background: rgba(0, 0, 0, 0) !important;
          opacity: 1 !important;
          border-width: 0px;
          line-height: 24px;
        }
        .local-tree-custom {
          display: flex;
          align-items: center;
          .local-tree-content {
            padding: 0 5px;
            color: #000000a6;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tree-more {
            color: #f2f4f7;
          }
        }
        .pdl15 {
          position: relative;
          left: -15px;
          .local-tree-content {
            width: calc(100%);
          }
          .tree-more {
            position: relative;
            right: -15px;
          }
        }
        .local-tree-custom:hover {
          .tree-more {
            color: #2281e1;
          }
        }
      }
    }
  }
}
</style>