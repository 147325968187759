/*
 * @Description: 
 * @Date: 2024-01-25 15:02:42
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-03-22 17:47:21
 */
import DeleteFilsystem from './fileModule/deleteFilesystem'
import AddServces from "./fileModule/servicesManage/index";
 const MixinFile = {
  methods: {
    convertFileTreeData(type, node, masterList) {
      this.treeSpain = true;
      this.treeData = []
      this.treeSpain = false;
      let treeNode = []
      masterList.map(item => {
        treeNode.push({
          name: item.name,
          isLeaf: false,
          key: 'groupAllHost-'+ item.id,
          id: item.id,
          clusterId: item.id,
          pid: 0,
          treeType: 'group',
          treeIconType: 'group',
          svgIcon: 'systemrootdrive',
          title: item.name,
          children: [],
          parentArr: [],
          path: '/',
          scopedSlots: { title: "host" },
        })
      })
      this.treefilterData = treeNode
      this.treeData = treeNode;
      this.convertHostTreeData(type, node)
    },
    getNodeByKey(tree, key) {
      if (!tree || !key || !Array.isArray(tree)) {
        return null;
      }
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.key === key) {
          return node;
        }
    
        if (node.children && Array.isArray(node.children)) {
          const foundNode = this.getNodeByKey(node.children, key);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    },
    getMasterNode() {
      if (!this.cluster_id) return false;
      this.treeSpain = true;
      const params = {
        host_name: this.searchValue,
        page_size: 1000,
        cluster_id: this.cluster_id,
        kind: 'local'
      };
      this.$axiosGet('side/filesystems', params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            res.data.results.map((item) => {
              const childNode = {
                title: item.name,
                name: item.name,
                svgIcon: 'group-host',
                key: 'master',
                isLeaf:  false,
                id: item.id,
                treeType: 'file',
                treeIconType: 'host',
                fullpath:  '/',
                path: '/',
                pid: 0,
                isChild: false,
                parentArr: [{
                  fullpath:  '/',
                  ...item,
                  name: '根目录',
                }],
                scopedSlots: {
                  title: 'host'
                },
                ...item,
              };
              this.treefilterData.push(childNode);
              this.treeData.push(childNode);
              this.labelsList.push(item.name);
            });
          }
        }
      });
    },
    onSelectFileTree (keys, node, fromTo = 'tree') {
      const _keys = this.selectedKeys
      if (this.winboxHost.fileRequestLoading) {
        this.selectedKeys = _keys
        return false
      }
      // 点击打开的父节点的时候将父节点选中收起来
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      if (!keys.length) return false;
      // 判断当前页面是否已经打开
      const realEditList = _.cloneDeep(this.winboxHost.realEditList)
      let openArr = []
      openArr =  realEditList.filter(item => item.tabselectKey === keys[0])
      this.setSelectTreeFlag(node.dataRef.treeIconType === 'file')
      this.setWinboxSelectTreeFlag({id: this.currentWinboxKey, value: node.dataRef.treeIconType === 'file'});
      if (openArr.length > 0)  {
        return this.changeTable({...openArr[0], item: {...openArr[0], activeKey: node.dataRef.treeIconType === 'host' ? 'dash-board' : openArr[0].activeKey}}, node.dataRef.treeIconType === 'file' ? 'setActiveKeyFile' : '')
      }
      if (!node.isLeaf) this.windowEventBus.$emit('changeLoading', 'loading')
      this.windowEventBus.$emit('setFileRequestLoading', true)
       const clusterId = node.dataRef.master
      let apiAjax = global.API.getProxyFile + `${node.dataRef.fullpath}` + '?cluster_id=' + clusterId
      const params = {
        filesystem: node.dataRef?.parentArr[0]?.id,
        keyword: ''
      };
      if (!node.isLeaf) {
        this.$axiosProxyGet(apiAjax, params).then((res) => {
          this.windowEventBus.$emit('changeLoading', 'ending')
          this.windowEventBus.$emit('setFileRequestLoading', false)         
          if ([200, 201, 204].includes(res.status)) {
            const result = res.data;
            this.selectedKeys = keys;
            if (node.dataRef.fullpath === '/') {
              this.pushTable({
                ...node.dataRef,
                activeKey: node.dataRef.treeIconType === 'file' ? 'file' : 'dash-board'
              });
            } else {
              let  obj = {}
              if (node.dataRef.parentArr[0].group_id) {
                let key = node.dataRef.parentArr[0].group_id + '-' + node.dataRef.parentArr[0].id
                obj = this.getNodeByKey(this.treeData, key)
              } else {
                obj = this.treeData.find(item => item.id === node.dataRef.parentArr[0].id)
              }
              this.pushTable({...obj, activeKey: node.dataRef.treeIconType === 'file' ? 'file' : 'dash-board', tabselectKey: keys[0]});
            }
            setTimeout(() => {
              // 这地方是第一次去加载文件列表
              this.windowEventBus.$emit('onGetFirstTree', result, node.dataRef)
            }, 300)
          } else {
            this.selectedKeys = _keys
            this.windowEventBus.$emit('changeLoading', 'ending')
            this.windowEventBus.$emit('setFileRequestLoading', false)
          }
        }).catch(() => {
          this.selectedKeys = _keys
          this.windowEventBus.$emit('changeLoading', 'ending')
          this.windowEventBus.$emit('setFileRequestLoading', false)
        })
      } else {
        if (global.utils.isPreviewFile(node.dataRef))  {
          this.selectedKeys = keys;
          let  obj = {}
          if (node.dataRef.parentArr[0].group_id) {
            let key = node.dataRef.parentArr[0].group_id + '-' + node.dataRef.parentArr[0].id
            obj = this.getNodeByKey(this.treeData, key)
          } else {
            obj = this.treeData.find(item => item.id === node.dataRef.parentArr[0].id)
          }
          this.windowEventBus.$emit('setFileRequestLoading', false)
          this.pushTable(obj);
          this.windowEventBus.$emit('setActiveKey')
          setTimeout(() => {
            this.windowEventBus.$emit('onOpenFile', node.dataRef, node.$parent.dataRef) 
          }, 100)
          return false
        }
        this.getEditFile(node.dataRef, keys, _keys)
      }
    },
    getEditFile (file, keys, _keys) {
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // if ((file.size / 1048576) > 2) {
      //   this.windowEventBus.$emit('setFileRequestLoading', false)      
      //   return this.$message.warning('文件大小超过2M，请使用其它编辑方式')
      // }
      const ajaxApi = global.API.downloadProxyFile
      setTimeout(() => {
        this.windowEventBus.$emit('setFileRequestLoading', false)      
      }, 6000)
      let self = this;
      this.$axiosProxyDown(ajaxApi + file.fullpath + '?cluster_id=' + file.cluster_id, { filesystem: file.parentArr[0].id}).then((res) => {
        this.windowEventBus.$emit('setFileRequestLoading', false)
        if ([200, 201, 204, 202].includes(res.status)) { 
          this.selectedKeys = keys;
          if (file.fullpath === '/') {
            this.pushTable(file);
          } else {
            let  obj = {}
            if (file.parentArr[0].group_id) {
              let key = file.parentArr[0].group_id + '-' + file.parentArr[0].id
              obj = this.getNodeByKey(this.treeData, key)
            } else {
              obj = this.treeData.find(item => item.id === file.parentArr[0].id)
            }
            obj.activeKey = 'file'
            this.pushTable(obj);
          }
          setTimeout(() => {
            self.$decodeData(res.data).then(code => {
              this.windowEventBus.$emit('onGetEditFile', code, file)
            })
          }, 0)
        } else {
          this.selectedKeys = _keys;
        }
      }).catch(res => {
        this.$message.error('当前文件获取内容失败')

      })
    },
    showFilePopDetail(keys, node) {
      /**
       * 操作集
       */
      this.popoverVisible = false;
      const self = this;
      let width =keys === "edit" ? 646 : 100;
      let title = "编辑服务";
      let content =
        keys === "edit" || keys === "build" ? (
          <AddServces detail={node.dataRef} clusterId={this.cluster_id} callBack={(param) => {
            self.convertTreeData('edit', node)
            self.editByRename(node, param.name)
          }} />
        ) : (
          <DeleteFilsystem
            file={node}
            type="group"
            clusterId={this.cluster_id}
            callBack={
              () => {
                self.convertTreeData('del')
                self.delByRemoveTree(node)
              } 
            }
          />
        );
      this.$confirm({
        width: width,
        title:
          keys === "edit" || keys === "build"
            ? title
            : () => {
              return (
                <div>
                  <a-icon
                    type="question-circle"
                    style="color:#2F7FD1 !important;margin-right:10px"
                  />
                  提示
                </div>
              );
            },
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
  },
}
export {
  MixinFile
}