function isOfficeFile(input) {
  // 办公文件扩展名列表（可根据需求扩展）
  const officeExtensions = new Set([
    'doc', 'docx', 'docm',      // Word
    'xls', 'xlsx', 'xlsm', "xlsb", "ods", "csv",      // Excel
    'ppt', 'pptx', 'pptm',      // PowerPoint
    'odt', 'ods', 'odp',        // OpenOffice
    'rtf', 'pdf'                // 其他文档
  ]);

  // 处理URL：去除查询参数和哈希，提取文件名
  const urlWithoutParams = input.split(/[?#]/)[0];
  const filename = urlWithoutParams.split('/').pop().toLowerCase();

  // 使用正则匹配扩展名
  const match = filename.match(/\.([a-z0-9]+)$/);
  if (!match) return false;

  const extension = match[1];
  return officeExtensions.has(extension);
}

function generateProgressHtml(filename, percentage) {
  return `
<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>简单加载条</title>
    <style>
        body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            font-family: Arial, sans-serif;
            flex-direction: column;
            background-color: white;
        }

        .loading-text {
            font-size: 14px;
            color: #6b6e76;
            font-weight: 450;
            margin-bottom: 5px;
            font-size: 20px;
        }

        .loading-container {
            width: 80%;
            max-width: 500px;
            display: flex;
            align-items: center;
        }

        .ant-progress-outer {
            width: 100%;
        }

        .ant-progress-inner {
            border: 1px solid rgba(57, 116, 244, 1);
            border-radius: 8px;
            background: #fff;
            height: 16px;
            padding: 2px;
            .ant-progress-bg {
            height: 10px !important;
            }
        }
        .ant-progress-text {
            font-size: 12px;
            color: #80848d;
            font-weight: 400;
        }
        .ant-progress-bg {
            width: ${percentage}%;
            height: 8px;
            border-radius: 100px;
            background-image: linear-gradient(to right, rgb(125, 160, 241) 0%, rgb(57, 116, 244) 100%);
        }
        .percentage {
            margin-left: 15px;
            font-size: 18px;
            color: #666;
            width: 24px;
            display: inline-block;
        }
    </style>
</head>
<body>
    <div class="loading-text">Loading...</div>
    <div class="loading-container">
        <div class="ant-progress-outer">
            <div class="ant-progress-inner" style="height: 16px;padding: 2px;border: 1px solid #3974f4;border-radius: 8px;box-sizing: border-box;">
                <div class="ant-progress-bg" style="height: 10px;"></div>
            </div>
        </div>
        <div class="percentage"><span style="font-size: 12px; color: #80848d; font-weight: 400; width:24px; ">${percentage}%</span></div>
    </div>
</body>
</html>
`
}

export {isOfficeFile, generateProgressHtml};